import { styled } from 'linaria/react';
import React, { Fragment } from 'react';
import { theme } from '../../Theming/Theming';
import { fixConvertData } from './VariantSelector';

const StyledButton = styled('button')`
  background: transparent;
  border: 1px solid ${theme.colors.primary};
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  //width: 31.333333%;
  padding: 0.3rem 0.7rem;
  margin: 0 1% 2%;
  font-size: 0.9rem;
  font-weight: ${theme.weights.bold};
  ${theme.above.md} {
    //width: 23%;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    margin: 0 1% 2%;
  }
  &[data-selected='true'] {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
  }
  &[data-disabled='true'] {
    cursor: not-allowed;
  }
  &.not-buyable,
  &[data-disabled='true'] {
    border-color: #eee;
    background: ${theme.colors.greys[0]};
    color: #aaa;
    text-decoration: line-through;
    &[data-selected='true'] {
      border-color: #777;
    }
  }
  &.color {
    padding: 0;
    .color-circle {
      > div {
        width: 36px;
        height: 36px;
        border-radius: 0;
        border: none;
      }
    }
  }
  .converted-size {
    font-size: 0.9em;
    margin-left: 5px;
  }
  &.converted {
    .value {
    }
    .converted-size {
    }
  }

  &.product-card-button {
    background: white;
    border: unset;
    cursor: default;
    padding: 0.5rem 0.5rem;
    color: #878787;
    .converted-size {
      margin-left: 0;
    }
    padding: 0;
    font-size: 0.85rem;
  }
`;
const VariantButtonWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 -1% 0;
  margin-bottom: ${({ productCard }) =>
    productCard ? '0rem !important' : '0.6rem'};
  ${theme.above.md} {
    margin-bottom: ${({ productCard }) =>
      productCard ? '0rem !important' : '1rem'};
  }
  &.invalid button {
    box-shadow: 0 0 10px rgba(195, 45, 45, 0.38);
  }
`;

export const VariantHeading = styled('p')`
  margin-bottom: ${({ productCard }) => (productCard ? '0rem' : '0.3rem')};
  font-weight: ${theme?.weights.bold};
`;

const VariantWrapper = styled('div')`
  width: 100%;
`;

const VariantButton = ({
  option,
  variantHandler,
  showValidationMessage,
  product,
  productCard
}) => {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    getVariantForSelection
  } = variantHandler;
  const selectedValue = getSelectedValue(option);
  const sizesObject = fixConvertData(product);
  return (
    <VariantWrapper>
      {!productCard && (
        <VariantHeading productCard={productCard}>{option.name}</VariantHeading>
      )}
      <VariantButtonWrapper
        productCard={productCard}
        className={showValidationMessage && 'invalid'}
      >
        {option.values
          .filter(value => {
            if (productCard) {
              const item = getVariantForSelection(value, option);
              console.log(item);
              return item && item.stockStatus && item.stockStatus.buyable;
            } else {
              return true;
            }
          })
          .map((value, index) => {
            const validation = validateSelection(value, option);
            const item = getVariantForSelection(value, option);
            const convertedSize = sizesObject ? sizesObject[value] : false;
            return (
              <Fragment key={option.name + value + product?.id}>
                {(!productCard || (productCard && validation === 'valid')) &&
                  (productCard && index === 5 ? (
                    <StyledButton
                      onClick={() => {
                        if (!productCard) {
                          selectValue(value, option);
                        }
                      }}
                      data-disabled={validation === 'invalid'}
                      data-secondary={value !== selectedValue}
                      data-selected={value === selectedValue}
                      className={`${
                        item && item.stockStatus && !item.stockStatus.buyable
                          ? 'not-buyable '
                          : ''
                      } ${convertedSize ? 'converted ' : ''} ${
                        productCard ? 'product-card-button' : ''
                      }`}
                    >
                      <span className="value">+</span>
                    </StyledButton>
                  ) : (
                    (!productCard || index < 5) && (
                      <StyledButton
                        onClick={() => {
                          if (!productCard) {
                            selectValue(value, option);
                          }
                        }}
                        data-disabled={validation === 'invalid'}
                        data-secondary={value !== selectedValue}
                        data-selected={value === selectedValue}
                        className={`${
                          item && item.stockStatus && !item.stockStatus.buyable
                            ? 'not-buyable '
                            : ''
                        } ${convertedSize ? 'converted ' : ''} ${
                          productCard ? 'product-card-button' : ''
                        }`}
                      >
                        <span className="value">{value}</span>
                        {!productCard && convertedSize && (
                          <span className="converted-size">
                            ({convertedSize})
                          </span>
                        )}
                      </StyledButton>
                    )
                  ))}
              </Fragment>
            );
          })}
      </VariantButtonWrapper>
    </VariantWrapper>
  );
};

export default VariantButton;
